<template>
  <header
    class="header"
    :class="{
      'filters-inactive': !isEventsFiltersActive,
    }"
  >
    <div class="header-top">
      <div class="header__title">
        <Heading>
          {{ selectionSubRouteLabel }}
        </Heading>
        <SubscriptionButton
          v-if="!eventListLoading && selectionCovered"
        />
      </div>

      <div class="header__content">
        <CreateEventReplayControl
          v-if="isEventReplaySupported && !competitionsSubscriptionRoute"
        />
        <CreateEventControl
          v-if="eventActionsActive && !competitionsSubscriptionRoute"
        />
        <div
          v-if="eventActionsActive && !competitionsSubscriptionRoute"
          class="divider"
        />
        <SearchField :placeholder="competitionsSubscriptionRoute ? 'Search ”NBA”, NFL...' : 'Search “New York Knicks”...'" />
        <!-- <RowSpacingFilter /> -->
        <Button
          v-if="!competitionsSubscriptionRoute"
          icon="three-columns"
          @click="setIsEventsSidebarActive"
        >
          Edit Columns
        </Button>
        <Button
          v-if="!competitionsSubscriptionRoute"
          icon="filter-funnel"
          @click="setIsEventsFiltersActive"
        >
          {{ isEventsFiltersActive ? 'Hide filters' : 'Show filters' }}
        </Button>
      </div>
    </div>
    <div
      v-if="isEventsFiltersActive && !competitionsSubscriptionRoute"
      class="header-bottom"
    >
      <FiltersMain />
    </div>
    <div
      v-if="competitionsSubscriptionRoute"
      class="header-bottom"
    >
      <CompetitionsSubscriptionHeaderFilters />
    </div>
  </header>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import { useSelectionLabel } from '@/composables';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button';
import SearchField from './SearchField';
// import RowSpacingFilter from './RowSpacingFilter';
import FiltersMain from './FiltersMain';
import SubscriptionButton from './SubscriptionButton';
import CreateEventControl from './CreateEventControl';
import CreateEventReplayControl from './CreateEventReplayControl';
import CompetitionsSubscriptionHeaderFilters from '@/components/events/competitions-subscription/CompetitionsSubscriptionHeaderFilters';
import env from '@/../config/env';

export default {
  components: {
    Heading,
    SearchField,
    // RowSpacingFilter,
    FiltersMain,
    SubscriptionButton,
    CreateEventControl,
    Button,
    CompetitionsSubscriptionHeaderFilters,
    CreateEventReplayControl,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const {
      label: selectionLabel,
      covered: selectionCovered,
    } = useSelectionLabel({
      initialLabel: 'All events',
    });
    const { isSuperAdmin } = getSuperAdminData();
    const eventActionsActive = computed(() => isSuperAdmin);
    const isEventReplaySupported = computed(() => !!env.replay);
    const routeName = computed(() => route?.name || '');
    const competitionsSubscriptionRoute = computed(() => routeName.value === 'competitions_subscription');
    const routeTitle = computed(() => route?.meta?.title || 'Untitled');
    const selectionSubRouteLabel = computed(
      () => (competitionsSubscriptionRoute.value && selectionLabel.value === 'All events' ? 'All competitions' : selectionLabel.value),
    );
    const title = computed(() => (routeName.value === 'events' ? selectionSubRouteLabel.value : routeTitle.value));
    const hasDpsTraderRole = computed(() => store.getters.isDpsTraderRole);
    const eventListLoading = computed(() => store.getters.eventListLoading);
    const isEventsFiltersActive = computed(() => store.getters.isEventsFiltersActive);

    const setIsEventsSidebarActive = () => {
      store.dispatch('setIsEventsSidebarActive', !store.getters.isEventsSidebarActive);
    };
    const setIsEventsFiltersActive = () => {
      store.dispatch('setIsEventsFiltersActive', !store.getters.isEventsFiltersActive);
    };

    return {
      title,
      isSuperAdmin,
      selectionLabel,
      selectionCovered,
      hasDpsTraderRole,
      eventListLoading,
      setIsEventsSidebarActive,
      isEventsFiltersActive,
      setIsEventsFiltersActive,
      eventActionsActive,
      competitionsSubscriptionRoute,
      selectionSubRouteLabel,
      isEventReplaySupported,
    };
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: $headerFiltersHeight;
  background-color: $eventsHeaderBackgroundColor;
  padding: map-get($spacers, 'l');
  border-radius: 10em;

  .header-top {
    display:  flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($spacers, 'm');
  }

  &.filters-inactive {
    height: $headerWithoutFiltersHeight;
    .header-top {
      margin-bottom: 0;
    }
  }

  .header__title {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 60%;

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .header__content {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .divider {
    width: 1px;
    height: 32px;
    background-color: $gray400;
  }
}
</style>
