<template>
  <div class="create-event-replay-control">
    <Button
      @click="showModal"
      :icon="'plus'"
    >
      Event replay
    </Button>
    <teleport to="body">
      <ReplayManagerSearch
        v-if="isModalVisible"
        @close="hideModal"
      />
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import Button from '@/components/common/Button';
import ReplayManagerSearch from '../../events/ReplayManagerSearch';

export default {
  components: {
    Button,
    ReplayManagerSearch,
  },
  setup() {
    const isModalVisible = ref(false);

    const showModal = () => {
      isModalVisible.value = true;
    };
    const hideModal = () => {
      isModalVisible.value = false;
    };

    return {
      isModalVisible,
      showModal,
      hideModal,
    };
  },
};
</script>

<style lang="scss">
.create-event-replay-control {
}
</style>
